.input-container {
  flex-grow: 1; }
  .input-container .input-label {
    display: block;
    margin-top: 16px;
    margin-bottom: 4px;
    font-size: 14px; }
  .input-container .input-label:first-child {
    margin-top: 0; }
  .input-container .input-text {
    width: 100%;
    display: block;
    font-size: 16px;
    padding: 6px 8px;
    border-radius: 2px;
    border: 2px solid #f1f1f1;
    font-family: "IBM Plex Sans", "PT Sans", sans-serif; }
  .input-container textarea.input-text {
    resize: none;
    height: 250px; }

.input-container:first-child {
  margin-right: 32px; }
