.sound-container {
  margin: 0px 0;
  display: flex;
  padding: 8px;
  padding-right: 16px; }
  .sound-container .sound-left {
    flex-grow: 1;
    margin-left: 40px; }
  .sound-container .sound-left.current {
    margin-left: 0; }
  .sound-container .sound-right {
    align-self: center; }
  .sound-container .sound-name {
    font-family: "Montserrat", "Helvetica", sans-serif;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.5px;
    color: #1f1f1f;
    line-height: 100%; }
    .sound-container .sound-name a:hover {
      text-decoration: underline; }
  .sound-container .sound-play.button,
  .sound-container .sound-pause.button {
    display: none;
    align-self: center;
    margin-right: 8px;
    font-size: 10px;
    width: 32px;
    height: 32px;
    padding: 0;
    border-radius: 16px; }
  .sound-container .sound-pause.button {
    display: block; }
  .sound-container .sound-meta-info {
    font-weight: normal;
    opacity: 0.35; }
  .sound-container .sound-tags,
  .sound-container .sound-date,
  .sound-container .sound-bpm,
  .sound-container .sound-plays,
  .sound-container .sound-purchases {
    font-family: "IBM Plex Sans", "PT Sans", sans-serif;
    font-size: 12px; }
  .sound-container .sound-price {
    display: inline-block;
    font-size: 14px;
    margin-left: 16px; }

.sound-container:hover .sound-left {
  margin-left: 0; }

.sound-container:hover .sound-play.button {
  display: block; }
