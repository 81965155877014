#now-playing-container {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 8px calc(10% + 32px);
  background: #f1f1f1;
  width: 100%;
  color: #1f1f1f;
  transition: bottom 200ms cubic-bezier(0.4, 0, 0.2, 1);
  justify-content: space-between; }
  #now-playing-container #now-playing-link {
    text-decoration: underline; }
  #now-playing-container #now-playing-link:hover {
    color: #2295f3; }
  #now-playing-container #now-playing-label {
    display: inline-block;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  #now-playing-container #now-playing-pause {
    width: 32px;
    height: 32px;
    font-size: 11px;
    border: 1px solid #2295f3;
    display: inline-block; }
