#filter-menu-container {
  width: 27%;
  max-width: 250px;
  padding: 0;
  background: #f1f1f1;
  margin-right: 16px;
  padding: 8px 16px;
  border-radius: 2px;
  user-select: none; }
  #filter-menu-container h3 {
    font-size: 20px; }
  #filter-menu-container .filter-label {
    font-family: "Montserrat", "Helvetica", sans-serif;
    font-weight: bold;
    font-size: 16px;
    color: #313131;
    display: block;
    margin: 8px 0; }
  #filter-menu-container .filter-range {
    width: 100%; }
  #filter-menu-container .filter-tag-label {
    position: relative;
    height: 24px;
    display: inline-block;
    padding-left: 32px;
    margin-bottom: 5px;
    font-family: "Montserrat", "Helvetica", sans-serif;
    font-weight: lighter;
    color: #313131;
    letter-spacing: -0.5px;
    cursor: pointer; }
    #filter-menu-container .filter-tag-label .filter-tag-label-text {
      position: relative;
      top: 2px; }
    #filter-menu-container .filter-tag-label .filter-tag-input {
      display: none; }
    #filter-menu-container .filter-tag-label .filter-tag-checkbox {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      background-color: #fff;
      border: 2px solid #e6e6e6; }
    #filter-menu-container .filter-tag-label .filter-tag-checkbox:after {
      display: none;
      content: '';
      position: absolute;
      left: 6px;
      top: 1px;
      width: 5px;
      height: 12px;
      border: solid #2295f3;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
    #filter-menu-container .filter-tag-label .filter-tag-input:checked ~ .filter-tag-checkbox:after {
      display: block; }
  #filter-menu-container .filter-tag-label:hover .filter-tag-checkbox {
    background: #fafafa; }
