#contact-container {
  font-family: "IBM Plex Sans", "PT Sans", sans-serif;
  width: 75%;
  max-width: 700px;
  min-width: 550px; }
  #contact-container h1 {
    margin-bottom: 16px; }
  #contact-container #contact-form {
    width: 100%; }
    #contact-container #contact-form .flex-box {
      margin-bottom: 16px; }
    #contact-container #contact-form #contact-send {
      font-size: 16px;
      float: right;
      margin-top: 16px;
      width: 100px; }
