#home-container {
  text-align: center; }
  #home-container #banner-container {
    color: #1f1f1f;
    text-align: left;
    padding: 10%; }
    #home-container #banner-container h1 {
      font-weight: lighter;
      font-size: 52px;
      line-height: 120%; }
  #home-container p {
    margin: 8px;
    margin-bottom: 50px; }
