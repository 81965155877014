#sound-player-container {
  width: 100%;
  padding-bottom: 16px;
  position: relative; }
  #sound-player-container #audio-canvas {
    display: block;
    width: 100%;
    height: 100px; }
  #sound-player-container audio {
    display: block;
    width: 100%; }
  #sound-player-container #progress-bar-container {
    position: absolute;
    width: 100%;
    height: 100px;
    top: 0;
    cursor: pointer; }
  #sound-player-container #progress-bar {
    width: 0;
    height: 100px;
    background: rgba(250, 250, 250, 0.5);
    position: absolute;
    top: 0;
    transition: width 0.2s; }
