header {
  font-family: 'IBM Plex Sans';
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
  color: #1f1f1f;
  position: sticky;
  top: 0;
  z-index: 10;
  opacity: 0.95;
  background: rgba(250, 250, 250, 0.95); }
  header #brand-name {
    letter-spacing: -1px;
    cursor: pointer; }
