.rollover-button-container {
  display: block;
  width: 150px;
  height: 36px;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  position: relative;
  font-family: "IBM Plex Sans", "PT Sans", sans-serif;
  font-size: 18px; }
  .rollover-button-container .rollover-button {
    display: block; }
  .rollover-button-container .rollover-before,
  .rollover-button-container .rollover-after {
    position: absolute;
    transition: top 150ms cubic-bezier(0.4, 0, 0.2, 1); }
  .rollover-button-container .rollover-before {
    left: 45px; }
  .rollover-button-container .rollover-after {
    left: 40px; }
