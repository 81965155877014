#category-showcase {
  font-family: "IBM Plex Sans", "PT Sans", sans-serif; }
  #category-showcase ul {
    display: flex;
    list-style: none;
    justify-content: space-between; }
    #category-showcase ul li {
      font-size: 1rem;
      flex-grow: 1;
      width: 33%;
      position: relative;
      margin-right: 64px;
      border-radius: 5px;
      color: #1f1f1f; }
      #category-showcase ul li a {
        display: block;
        position: relative;
        width: 100%;
        padding: 80px;
        font-weight: normal;
        letter-spacing: -1px;
        white-space: nowrap; }
      #category-showcase ul li a::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        background: transparent;
        border: 2px solid #1f1f1f;
        border-radius: 5px;
        transform: scale(1);
        opacity: 1;
        transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1); }
      #category-showcase ul li a:hover::after {
        transform: scale(0.75);
        opacity: 1;
        border: 3px solid #2295f3; }
      #category-showcase ul li a:hover {
        color: #2295f3; }
    #category-showcase ul li:hover {
      border-color: transparent; }
    #category-showcase ul li:last-of-type {
      margin-right: 0; }

/* *** Lyrics to our impromptu song recording ***
 * Swerving in the civic lane to lane we be switchin
 * * Don't you miss it, blink twice and we'll hit it
 * Be the gas, hit it fast, never last 
 * * Be the tree, we stop fast, through the windsheild and the glass
 * Doesn't matter, we roll fatter, everynight getting louder
 * * hitting harder homers than the dedicated batter
 * Then we stop any home, pick a basement pick a phone,
 * * Hit up any girl near the neighborhood zone 
 * Sit a little while, spit a freestyle, cook up a beat,
 * * Sit on it awhile, get a little higher
 * And here we sit, bored as shit, mind feeling on the fritz
 * * And what to do? Never knew, the question only ever grew.
 **/
