#sound-page-container {
  position: relative;
  overflow: auto; }
  #sound-page-container #sound-name {
    font-family: "Montserrat", "Helvetica", sans-serif;
    display: inline-block;
    position: absolute;
    left: 40px;
    top: -5px; }
  #sound-page-container .audio-control {
    display: inline-block;
    padding: 0;
    color: #2295f3;
    font-size: 26px;
    margin-right: 10px;
    background: none;
    position: absolute;
    left: 0;
    top: 0;
    border: none; }
  #sound-page-container #pause-button {
    position: absolute;
    top: -3px;
    left: -3px; }
  #sound-page-container #sound-meta {
    margin-top: 40px; }
