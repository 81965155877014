.collapsible-list ul {
  list-style: none; }
  .collapsible-list ul .collapsible-button {
    color: #2295f3;
    font-family: "IBM Plex Sans", "PT Sans", sans-serif;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer; }
  .collapsible-list ul .collapsible-button:hover {
    text-decoration: underline; }
