nav {
  align-self: center; }
  nav ul {
    list-style: none;
    display: flex;
    justify-content: space-between; }
    nav ul li {
      display: inline-block;
      flex-grow: 1;
      margin-right: 20px;
      position: relative;
      z-index: 10; }
      nav ul li a {
        display: block;
        text-align: center;
        width: 100%;
        padding: 8px;
        font-weight: bolder;
        transition: all 150ms ease-out; }
      nav ul li a:hover {
        color: #2295f3; }
    nav ul li:last-of-type {
      margin-right: 0; }
  nav #active-tracker {
    opacity: 0;
    background: #1f1f1f;
    position: absolute;
    z-index: 1;
    transition: all 200ms ease-in; }
