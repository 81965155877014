@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans:200,400,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400);
nav {
  align-self: center; }
  nav ul {
    list-style: none;
    display: flex;
    justify-content: space-between; }
    nav ul li {
      display: inline-block;
      flex-grow: 1;
      margin-right: 20px;
      position: relative;
      z-index: 10; }
      nav ul li a {
        display: block;
        text-align: center;
        width: 100%;
        padding: 8px;
        font-weight: bolder;
        transition: all 150ms ease-out; }
      nav ul li a:hover {
        color: #2295f3; }
    nav ul li:last-of-type {
      margin-right: 0; }
  nav #active-tracker {
    opacity: 0;
    background: #1f1f1f;
    position: absolute;
    z-index: 1;
    transition: all 200ms ease-in; }

header {
  font-family: 'IBM Plex Sans';
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
  color: #1f1f1f;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  opacity: 0.95;
  background: rgba(250, 250, 250, 0.95); }
  header #brand-name {
    letter-spacing: -1px;
    cursor: pointer; }

#category-showcase {
  font-family: "IBM Plex Sans", "PT Sans", sans-serif; }
  #category-showcase ul {
    display: flex;
    list-style: none;
    justify-content: space-between; }
    #category-showcase ul li {
      font-size: 1rem;
      flex-grow: 1;
      width: 33%;
      position: relative;
      margin-right: 64px;
      border-radius: 5px;
      color: #1f1f1f; }
      #category-showcase ul li a {
        display: block;
        position: relative;
        width: 100%;
        padding: 80px;
        font-weight: normal;
        letter-spacing: -1px;
        white-space: nowrap; }
      #category-showcase ul li a::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        background: transparent;
        border: 2px solid #1f1f1f;
        border-radius: 5px;
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
        transition: -webkit-transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
        transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
        transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 200ms cubic-bezier(0.4, 0, 0.2, 1); }
      #category-showcase ul li a:hover::after {
        -webkit-transform: scale(0.75);
                transform: scale(0.75);
        opacity: 1;
        border: 3px solid #2295f3; }
      #category-showcase ul li a:hover {
        color: #2295f3; }
    #category-showcase ul li:hover {
      border-color: transparent; }
    #category-showcase ul li:last-of-type {
      margin-right: 0; }

/* *** Lyrics to our impromptu song recording ***
 * Swerving in the civic lane to lane we be switchin
 * * Don't you miss it, blink twice and we'll hit it
 * Be the gas, hit it fast, never last 
 * * Be the tree, we stop fast, through the windsheild and the glass
 * Doesn't matter, we roll fatter, everynight getting louder
 * * hitting harder homers than the dedicated batter
 * Then we stop any home, pick a basement pick a phone,
 * * Hit up any girl near the neighborhood zone 
 * Sit a little while, spit a freestyle, cook up a beat,
 * * Sit on it awhile, get a little higher
 * And here we sit, bored as shit, mind feeling on the fritz
 * * And what to do? Never knew, the question only ever grew.
 **/

.page-break {
  padding: 16px;
  margin: 32px 0; }

#home-container {
  text-align: center; }
  #home-container #banner-container {
    color: #1f1f1f;
    text-align: left;
    padding: 10%; }
    #home-container #banner-container h1 {
      font-weight: lighter;
      font-size: 52px;
      line-height: 120%; }
  #home-container p {
    margin: 8px;
    margin-bottom: 50px; }

#sound-player-container {
  width: 100%;
  padding-bottom: 16px;
  position: relative; }
  #sound-player-container #audio-canvas {
    display: block;
    width: 100%;
    height: 100px; }
  #sound-player-container audio {
    display: block;
    width: 100%; }
  #sound-player-container #progress-bar-container {
    position: absolute;
    width: 100%;
    height: 100px;
    top: 0;
    cursor: pointer; }
  #sound-player-container #progress-bar {
    width: 0;
    height: 100px;
    background: rgba(250, 250, 250, 0.5);
    position: absolute;
    top: 0;
    transition: width 0.2s; }

.sound-container {
  margin: 0px 0;
  display: flex;
  padding: 8px;
  padding-right: 16px; }
  .sound-container .sound-left {
    flex-grow: 1;
    margin-left: 40px; }
  .sound-container .sound-left.current {
    margin-left: 0; }
  .sound-container .sound-right {
    align-self: center; }
  .sound-container .sound-name {
    font-family: "Montserrat", "Helvetica", sans-serif;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.5px;
    color: #1f1f1f;
    line-height: 100%; }
    .sound-container .sound-name a:hover {
      text-decoration: underline; }
  .sound-container .sound-play.button,
  .sound-container .sound-pause.button {
    display: none;
    align-self: center;
    margin-right: 8px;
    font-size: 10px;
    width: 32px;
    height: 32px;
    padding: 0;
    border-radius: 16px; }
  .sound-container .sound-pause.button {
    display: block; }
  .sound-container .sound-meta-info {
    font-weight: normal;
    opacity: 0.35; }
  .sound-container .sound-tags,
  .sound-container .sound-date,
  .sound-container .sound-bpm,
  .sound-container .sound-plays,
  .sound-container .sound-purchases {
    font-family: "IBM Plex Sans", "PT Sans", sans-serif;
    font-size: 12px; }
  .sound-container .sound-price {
    display: inline-block;
    font-size: 14px;
    margin-left: 16px; }

.sound-container:hover .sound-left {
  margin-left: 0; }

.sound-container:hover .sound-play.button {
  display: block; }

.sound-list-container {
  width: 100%; }
  .sound-list-container ul {
    list-style: none; }
    .sound-list-container ul li {
      padding: 0px 0;
      border-bottom: 1px solid #f1f1f1; }
    .sound-list-container ul li:last-child {
      border-bottom: none; }

.range-input-container.container.fw {
  position: relative;
  display: block;
  overflow: visible;
  margin: 8px 0;
  padding: 0 16px; }
  .range-input-container.container.fw .range-input-selection {
    position: absolute;
    background: #2295f3;
    width: 100%;
    height: 2px;
    left: 0;
    top: 15px; }
  .range-input-container.container.fw .range-input-bar {
    position: absolute;
    top: 0;
    left: 16px;
    display: block;
    height: 32px;
    width: calc(100% - 32px); }
  .range-input-container.container.fw .range-input-bar:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    top: 15px;
    border-radius: 2px;
    background: rgba(49, 49, 49, 0.5); }
  .range-input-container.container.fw .range-input-thumb {
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    position: absolute;
    top: 0;
    left: 0;
    background: #2295f3;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    touch-action: none;
    box-sizing: content-box; }
    .range-input-container.container.fw .range-input-thumb span {
      display: block;
      position: relative;
      top: -1.5px;
      color: #fafafa;
      line-height: 100%;
      font-size: 30px;
      width: 100%;
      height: 100%; }
  .range-input-container.container.fw .range-input-meta {
    display: block;
    padding-top: 32px; }
    .range-input-container.container.fw .range-input-meta .input-text {
      display: inline-block;
      width: 60px; }

.collapsible-list ul {
  list-style: none; }
  .collapsible-list ul .collapsible-button {
    color: #2295f3;
    font-family: "IBM Plex Sans", "PT Sans", sans-serif;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer; }
  .collapsible-list ul .collapsible-button:hover {
    text-decoration: underline; }

#filter-menu-container {
  width: 27%;
  max-width: 250px;
  padding: 0;
  background: #f1f1f1;
  margin-right: 16px;
  padding: 8px 16px;
  border-radius: 2px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  #filter-menu-container h3 {
    font-size: 20px; }
  #filter-menu-container .filter-label {
    font-family: "Montserrat", "Helvetica", sans-serif;
    font-weight: bold;
    font-size: 16px;
    color: #313131;
    display: block;
    margin: 8px 0; }
  #filter-menu-container .filter-range {
    width: 100%; }
  #filter-menu-container .filter-tag-label {
    position: relative;
    height: 24px;
    display: inline-block;
    padding-left: 32px;
    margin-bottom: 5px;
    font-family: "Montserrat", "Helvetica", sans-serif;
    font-weight: lighter;
    color: #313131;
    letter-spacing: -0.5px;
    cursor: pointer; }
    #filter-menu-container .filter-tag-label .filter-tag-label-text {
      position: relative;
      top: 2px; }
    #filter-menu-container .filter-tag-label .filter-tag-input {
      display: none; }
    #filter-menu-container .filter-tag-label .filter-tag-checkbox {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      background-color: #fff;
      border: 2px solid #e6e6e6; }
    #filter-menu-container .filter-tag-label .filter-tag-checkbox:after {
      display: none;
      content: '';
      position: absolute;
      left: 6px;
      top: 1px;
      width: 5px;
      height: 12px;
      border: solid #2295f3;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
    #filter-menu-container .filter-tag-label .filter-tag-input:checked ~ .filter-tag-checkbox:after {
      display: block; }
  #filter-menu-container .filter-tag-label:hover .filter-tag-checkbox {
    background: #fafafa; }

.rollover-button-container {
  display: block;
  width: 150px;
  height: 36px;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  position: relative;
  font-family: "IBM Plex Sans", "PT Sans", sans-serif;
  font-size: 18px; }
  .rollover-button-container .rollover-button {
    display: block; }
  .rollover-button-container .rollover-before,
  .rollover-button-container .rollover-after {
    position: absolute;
    transition: top 150ms cubic-bezier(0.4, 0, 0.2, 1); }
  .rollover-button-container .rollover-before {
    left: 45px; }
  .rollover-button-container .rollover-after {
    left: 40px; }

#sound-page-container {
  position: relative;
  overflow: auto; }
  #sound-page-container #sound-name {
    font-family: "Montserrat", "Helvetica", sans-serif;
    display: inline-block;
    position: absolute;
    left: 40px;
    top: -5px; }
  #sound-page-container .audio-control {
    display: inline-block;
    padding: 0;
    color: #2295f3;
    font-size: 26px;
    margin-right: 10px;
    background: none;
    position: absolute;
    left: 0;
    top: 0;
    border: none; }
  #sound-page-container #pause-button {
    position: absolute;
    top: -3px;
    left: -3px; }
  #sound-page-container #sound-meta {
    margin-top: 40px; }

#beats-container {
  padding-top: 0;
  padding-bottom: 100px; }
  #beats-container h1 {
    font-size: 42px;
    padding-bottom: 16px; }

.input-container {
  flex-grow: 1; }
  .input-container .input-label {
    display: block;
    margin-top: 16px;
    margin-bottom: 4px;
    font-size: 14px; }
  .input-container .input-label:first-child {
    margin-top: 0; }
  .input-container .input-text {
    width: 100%;
    display: block;
    font-size: 16px;
    padding: 6px 8px;
    border-radius: 2px;
    border: 2px solid #f1f1f1;
    font-family: "IBM Plex Sans", "PT Sans", sans-serif; }
  .input-container textarea.input-text {
    resize: none;
    height: 250px; }

.input-container:first-child {
  margin-right: 32px; }

#contact-container {
  font-family: "IBM Plex Sans", "PT Sans", sans-serif;
  width: 75%;
  max-width: 700px;
  min-width: 550px; }
  #contact-container h1 {
    margin-bottom: 16px; }
  #contact-container #contact-form {
    width: 100%; }
    #contact-container #contact-form .flex-box {
      margin-bottom: 16px; }
    #contact-container #contact-form #contact-send {
      font-size: 16px;
      float: right;
      margin-top: 16px;
      width: 100px; }

body,
html {
  margin: 0;
  padding: 0;
  background: #fafafa; }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

*::-moz-selection {
  background: #9dd0fa; }

*::selection {
  background: #9dd0fa; }

body {
  width: 82%;
  margin: 0 auto;
  padding: 0 64px;
  background: #fafafa; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "IBM Plex Sans", "PT Sans", sans-serif; }

a,
a:visited,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
  font-style: inherit;
  outline: none; }

.highlight-header {
  font-size: 64px;
  font-style: italic;
  display: inline-block;
  line-height: 90%;
  color: #1f1f1f;
  position: relative;
  z-index: 40; }

.highlight-header::before {
  content: '';
  background: #2295f3;
  width: 100%;
  height: 100%;
  display: block;
  bottom: -14px;
  left: -14px;
  position: absolute;
  z-index: -1; }

input[type='button'].button,
a.button {
  outline: none;
  border: 1px solid #2295f3;
  border-radius: 2px;
  background: #2295f3;
  color: #fafafa;
  cursor: pointer;
  padding: 8px;
  font-family: "IBM Plex Sans", "PT Sans", sans-serif; }

input[type='button'].button:hover,
a.button:hover {
  background: #2295f3;
  color: #fafafa; }

.light {
  font-weight: lighter; }

.heavy {
  font-weight: bolder; }

.normal {
  font-weight: normal; }

.sound-tags {
  display: inline-block; }

.sound-tag {
  margin-right: 4px;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0.35;
  font-family: "IBM Plex Sans", "PT Sans", sans-serif;
  font-weight: normal;
  color: #313131; }
  .sound-tag a {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 2px;
    border: 1px solid #313131;
    background: transparent;
    font-size: 11px; }

.sound-tag:hover {
  opacity: 1; }

.container {
  width: 70%;
  margin: 0 auto;
  padding: 16px 0;
  overflow: auto; }

.container.fw {
  width: 100%;
  max-width: none;
  min-width: none; }

.flex-box {
  display: flex; }

.flex-box.space-between {
  justify-content: space-between; }

.flex-vcenter {
  align-self: center; }

#now-playing-prog-container {
  position: relative;
  width: 72%;
  display: inline-block;
  margin-left: 32px;
  display: inline-block; }
  #now-playing-prog-container #now-playing-prog-bar {
    width: 100%;
    height: 32px;
    background: transparent;
    position: relative;
    cursor: pointer; }
  #now-playing-prog-container #now-playing-prog-bar:after {
    content: '';
    position: absolute;
    margin: 0;
    padding: 0 16px;
    width: 100%;
    height: 2px;
    background: #313131;
    top: 16px;
    border: none;
    opacity: 0.35; }
  #now-playing-prog-container #now-playing-handle {
    background: #f1f1f1;
    border: 2px solid #2295f3;
    width: 24px;
    height: 24px;
    border-radius: 16px;
    position: absolute;
    top: 4px;
    transition: left 150ms; }

#now-playing-container {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 8px calc(10% + 32px);
  background: #f1f1f1;
  width: 100%;
  color: #1f1f1f;
  transition: bottom 200ms cubic-bezier(0.4, 0, 0.2, 1);
  justify-content: space-between; }
  #now-playing-container #now-playing-link {
    text-decoration: underline; }
  #now-playing-container #now-playing-link:hover {
    color: #2295f3; }
  #now-playing-container #now-playing-label {
    display: inline-block;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  #now-playing-container #now-playing-pause {
    width: 32px;
    height: 32px;
    font-size: 11px;
    border: 1px solid #2295f3;
    display: inline-block; }

