#now-playing-prog-container {
  position: relative;
  width: 72%;
  display: inline-block;
  margin-left: 32px;
  display: inline-block; }
  #now-playing-prog-container #now-playing-prog-bar {
    width: 100%;
    height: 32px;
    background: transparent;
    position: relative;
    cursor: pointer; }
  #now-playing-prog-container #now-playing-prog-bar:after {
    content: '';
    position: absolute;
    margin: 0;
    padding: 0 16px;
    width: 100%;
    height: 2px;
    background: #313131;
    top: 16px;
    border: none;
    opacity: 0.35; }
  #now-playing-prog-container #now-playing-handle {
    background: #f1f1f1;
    border: 2px solid #2295f3;
    width: 24px;
    height: 24px;
    border-radius: 16px;
    position: absolute;
    top: 4px;
    transition: left 150ms; }
