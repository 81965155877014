@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans:200,400,700");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400");
body,
html {
  margin: 0;
  padding: 0;
  background: #fafafa; }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

*::selection {
  background: #9dd0fa; }

body {
  width: 82%;
  margin: 0 auto;
  padding: 0 64px;
  background: #fafafa; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "IBM Plex Sans", "PT Sans", sans-serif; }

a,
a:visited,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
  font-style: inherit;
  outline: none; }

.highlight-header {
  font-size: 64px;
  font-style: italic;
  display: inline-block;
  line-height: 90%;
  color: #1f1f1f;
  position: relative;
  z-index: 40; }

.highlight-header::before {
  content: '';
  background: #2295f3;
  width: 100%;
  height: 100%;
  display: block;
  bottom: -14px;
  left: -14px;
  position: absolute;
  z-index: -1; }

input[type='button'].button,
a.button {
  outline: none;
  border: 1px solid #2295f3;
  border-radius: 2px;
  background: #2295f3;
  color: #fafafa;
  cursor: pointer;
  padding: 8px;
  font-family: "IBM Plex Sans", "PT Sans", sans-serif; }

input[type='button'].button:hover,
a.button:hover {
  background: #2295f3;
  color: #fafafa; }

.light {
  font-weight: lighter; }

.heavy {
  font-weight: bolder; }

.normal {
  font-weight: normal; }

.sound-tags {
  display: inline-block; }

.sound-tag {
  margin-right: 4px;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0.35;
  font-family: "IBM Plex Sans", "PT Sans", sans-serif;
  font-weight: normal;
  color: #313131; }
  .sound-tag a {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 2px;
    border: 1px solid #313131;
    background: transparent;
    font-size: 11px; }

.sound-tag:hover {
  opacity: 1; }

.container {
  width: 70%;
  margin: 0 auto;
  padding: 16px 0;
  overflow: auto; }

.container.fw {
  width: 100%;
  max-width: none;
  min-width: none; }

.flex-box {
  display: flex; }

.flex-box.space-between {
  justify-content: space-between; }

.flex-vcenter {
  align-self: center; }
