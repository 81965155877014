.range-input-container.container.fw {
  position: relative;
  display: block;
  overflow: visible;
  margin: 8px 0;
  padding: 0 16px; }
  .range-input-container.container.fw .range-input-selection {
    position: absolute;
    background: #2295f3;
    width: 100%;
    height: 2px;
    left: 0;
    top: 15px; }
  .range-input-container.container.fw .range-input-bar {
    position: absolute;
    top: 0;
    left: 16px;
    display: block;
    height: 32px;
    width: calc(100% - 32px); }
  .range-input-container.container.fw .range-input-bar:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    top: 15px;
    border-radius: 2px;
    background: rgba(49, 49, 49, 0.5); }
  .range-input-container.container.fw .range-input-thumb {
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    position: absolute;
    top: 0;
    left: 0;
    background: #2295f3;
    text-align: center;
    cursor: pointer;
    user-select: none;
    touch-action: none;
    box-sizing: content-box; }
    .range-input-container.container.fw .range-input-thumb span {
      display: block;
      position: relative;
      top: -1.5px;
      color: #fafafa;
      line-height: 100%;
      font-size: 30px;
      width: 100%;
      height: 100%; }
  .range-input-container.container.fw .range-input-meta {
    display: block;
    padding-top: 32px; }
    .range-input-container.container.fw .range-input-meta .input-text {
      display: inline-block;
      width: 60px; }
